<template>
  <div>
    <div class="loginTipBox" v-if="!$store.state.isLogin">
      <div class="loginTipIcon">
        <img style="width: 100%" src="@/assets/loginTipImg.png" alt="" />
      </div>
      <!-- 登录即可畅玩所有游戏 -->
      {{ $t("common.loginTip.signInToPlayAllGames") }}
      <van-button style="
          float: right;
          margin-top: 7px;
          margin-right: 7px;
          border-radius: 15px;
          min-width: 75px;
        " color="#52e400" size="mini" @click="showLoginOptionActionSheet(true)">
        {{ $t("common.loginTip.signInNow") }}</van-button>
    </div>

    <van-action-sheet style="background-color: #232631; color: white" title="" v-model="$store.state.isLoginActionSheet"
      @closed="closeLoginActionSheet">
      <div class="loginPageImg">
        <img src="@/assets/loginImg.png" style="width: 100%; height: 100%" alt="" />
      </div>

      <div class="loginMethodBox">
        <!-- <div class="nickName">
          <van-field type="text" maxlength="20" style="
            background-color: #45474d;
            line-height: 18px;
            border-radius: 10px;
            font-size: 12px;
            margin-top: 20px;
          " input-align="center" class="nickNameInp" :placeholder="userInfo.name == undefined || userInfo.name.length <= 0
            ? $t('common.login.inputTip')
            : userInfo.name" />
        </div> -->

        <van-divider :style="{
          color: 'white',
          borderColor: 'gray',
          fontSize: '9px',
          padding: '0 10px',
          margin: '7px 0',
          marginTop: '18px',
        }">
          {{ $t("common.loginTip.selectAcount") }}
        </van-divider>
        <div class="loginMethodItem" @click="loginMethod('google')">
          <van-button size="small" style="
              font-size: 12px;
              border-radius: 10px;
              height: 37px;
              width: 100%;
              overflow: hidden;
              background-image: linear-gradient(to right, #fe46ee, #c000f4);
              outline: none;
            " type="info" block>
            {{ $t("common.loginTip.signInWithGoogle") }}
          </van-button>
        </div>
        <!-- <div class="loginMethodItem" @click="loginMethod('facebook')">
          <van-button size="small" style="
              font-size: 12px;
              border-radius: 10px;
              height: 37px;
              width: 100%;
              background-image: linear-gradient(to right, #fe46ee, #c000f4);
            " type="info" block>
            {{ $t("common.loginTip.signInWithFB") }}
          </van-button>
        </div> -->
        <div class="loginMethodItem" @click="loginMethod('email')">
          <van-button size="small" style="
              font-size: 12px;
              border-radius: 10px;
              height: 37px;
              width: 100%;
              background-image: linear-gradient(to right, #fe46ee, #c000f4);
            " type="info" block>
            {{ $t("common.loginTip.signInWithEmail") }}
          </van-button>
        </div>
      </div>
    </van-action-sheet>

    <van-action-sheet style="background-color: #232631; color: white" title="" v-model="loginByEmailCheckCode">
      <div class="loginPageImg">
        <img src="@/assets/loginImg.png" style="width: 100%; height: 100%" alt="" />
      </div>

      <div class="loginMethodBox">
        <!-- <div class="nickName">
          <van-field type="digit" maxlength="20" style="
            background-color: #45474d;
            line-height: 18px;
            border-radius: 10px;
            font-size: 12px;
            margin-top: 20px;
          " input-align="center" class="nickNameInp" :placeholder="$t('common.login.inputTip')" />
        </div> -->

        <van-divider :style="{
          color: 'white',
          borderColor: 'gray',
          fontSize: '9px',
          padding: '0 10px',
          margin: '5px 0',
          marginTop: '18px',
        }">
          {{ $t("common.loginTip.emailAccountLogin") }}
        </van-divider>

        <van-field v-model="loginInfo.email" :label="$t('common.login.account')" style="
            max-height: 39px;
            background-color: #45474d;
            line-height: 18px;
            border-radius: 10px;
            font-size: 12px;
            border: 1px solid #c708f4;
          " :placeholder="$t('common.loginTip.enterEmail')" />

        <van-field v-model="loginInfo.checkCode" center clearable :label="$t('common.login.verifyCode')" style="
            max-height: 39px;
            background-color: #45474d;
            line-height: 18px;
            border-radius: 10px;
            font-size: 12px;
            margin: 10px 0;
            border: 1px solid #c708f4;
            max-height: 40px;
          " :placeholder="$t('common.loginTip.enterCheckCode')">
          <template #button>
            <van-button size="mini" color="#c708f2" style="border-radius: 6px" v-if="!sent" @click="sendCheckCode"
              type="primary">
              {{ $t("common.loginTip.sendCheckCode") }}
            </van-button>
            <van-count-down v-else-if="sent" :time="limitTime" format="HH:mm:ss" />
          </template>
        </van-field>

        <van-button style="
            background-image: linear-gradient(to right, #ff48ef, #c000f4);
            border: none;
            max-height: 40px;
            border-radius: 10px;
          " type="primary" block @click="signInByEmail">{{ $t("common.login.signIn") }}</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { Toast } from "vant";
import { mapState, mapActions } from "vuex";
import api from "@/request/api";
export default {
  props: {},
  data() {
    return {
      loginByEmailCheckCode: false,
      loginInfo: {
        name: "",
        email: "",
        password: "",
        checkCode: "",
      },
      userInfo: {},
      sent: false,
      limitTime: 0,
    };
  },

  watch: {
    params: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  // computed: {
  //   ...mapState({
  //     count: (state) => state.isLoginActionSheet,
  //   }),
  // },

  methods: {

    closeLoginActionSheet() {
      this.$store.commit("showLoginActionSheet", false);
    },

    showLoginOptionActionSheet(e) {
      this.$store.commit("showLoginActionSheet", e);
    },

    changeLoginStatus(e) {
      this.$store.commit("changeLoginStatus", e);
    },

    loginMethod(e) {
      switch (e) {
        case "google":
          this.signInByGoogle();
          break;
        case "facebook":
          this.faceBookSignIn();
          break;
        case "email":
          this.showLoginOptionActionSheet(false);
          this.loginByEmailCheckCode = true;
          break;
        default:
          this.changeLoginStatus(true);
          break;
      }
    },

    faceBookSignIn() {
      FB.login(function (response) {
        if (response.authResponse) {
          FB.api("/me", { fields: "name, email" }, function (response) { });
        } else {
        }
      });
    },

    async signInByGoogle() {
      try {
        let axiosResult = await api.signInByGoogle();
        if (axiosResult.code == 10041) {
          location.href = axiosResult.data;
          return;
        }
        Toast(this.$t("common.axios.failed"));
      } catch (error) {
        Toast(this.$t("common.axios.failed"));
      }
    },

    faceBookInfoInit() {
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");

      window.fbAsyncInit = function () {
        FB.init({
          appId: "1878765522652856",
          xfbml: true,
          version: "v21.0",
        });
      };
    },

    async sendCheckCode() {
      // const { v4: uuidv4 } = require('uuid');
      // const uuid = uuidv4();
      // localStorage.setItem('7topgame.com-register-code-uniqueId', uuid)

      let checkEmailFormatResult = this.checkEmailFormat(this.loginInfo.email);
      if (checkEmailFormatResult == 0) {
        Toast(this.$t("common.loginTip.notSupportEmail"));
        return;
      }

      try {
        let axiosResult = await api.sendEmailCheckCode(this.loginInfo.email);
        if (axiosResult.code == 20100 || axiosResult.code == 10011) {
          this.sent = true;
          this.limitTime = axiosResult.data;
          setTimeout(() => {
            this.sent = false;
          }, axiosResult.data);
          return;
        }

        Toast(this.$t("common.axios.failed"));
      } catch (error) {
        Toast(this.$t("common.axios.failed"));
      }
    },

    changeUserInfo(data) {
      this.$EventBus.$emit("changeUserInfo", data);
    },

    async signInByEmail() {
      let checkEmailFormatResult = this.checkEmailFormat(this.loginInfo.email);
      if (checkEmailFormatResult == 0) {
        Toast(this.$("common.loginTip.notSupportEmail"));
        return;
      }

      // if (this.loginInfo.checkCode.length < 3) {
      //   Toast("Check code length less than 3");
      //   return;
      // }

      this.loginInfo.area = navigator.language || navigator.userLanguage;

      try {
        let axiosResult = await api.quicklyLogin(this.loginInfo);
        if (axiosResult.code == 10041 || axiosResult.code == 10011) {
          localStorage.clear();
          sessionStorage.clear();
          axiosResult.data.isTourist = false;
          this.changeUserInfo(axiosResult.data);
          localStorage.setItem(
            "7topgame.com-user-info",
            JSON.stringify(axiosResult.data)
          );
          localStorage.setItem("7topgame.com-user-lang", axiosResult.data.area);
          this.loginByEmailCheckCode = false;
          this.changeLoginStatus(true);
          Toast(this.$t("common.axios.signInSuccessful"));
          // let source = JSON.parse(sessionStorage.getItem('7topgame.com-source'))
          // this.$router.push(source.routerPath)
          return;
        }

        if (axiosResult.code == 20090) {
          Toast(this.$t("common.axios.accountDisable"));
          return;
        }

        // if (res.data.code == 10040) {
        //   Toast(this.$t("common.axios.accountIsNotExisting"));
        //   return
        // }

        Toast(this.$t("common.axios.failed"));
      } catch (error) {
        Toast(this.$t("common.axios.failed"));
      }
    },

    checkEmailFormat(email) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (emailPattern.test(email)) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  mounted() {
    this.faceBookInfoInit();

    this.userInfo = JSON.parse(localStorage.getItem("7topgame.com-user-info"));
    if (this.userInfo == null) {
      localStorage.setItem("7topgame.com-user-info", JSON.stringify({}));
      this.userInfo = {};
      this.changeLoginStatus(false);
    }
  },
};
</script>

<style scoped>
.loginTipBox {
  position: relative;
  margin: 0 auto;
  width: 91%;
  height: 35px;
  line-height: 35px;
  background-color: #353535;
  border-radius: 25px;
  font-size: 12px;
  text-align: center;
  color: white;
}

.loginTipIcon {
  position: absolute;
  left: 5px;
  top: 3px;
  width: 30px;
  height: 30px;
  border-radius: 17.5px;
  background-color: white;
}

.loginPageImg {
  margin: 0 auto;
  margin-top: 13px;
  width: 91%;
  max-width: 341px;
  height: 140px;
  background-color: #100c1e;
  border-radius: 12px;
  overflow: hidden;
}

.loginMethodBox {
  margin: 18px auto;
  margin-bottom: 17px;
  width: 70%;
  min-height: 160px;
  /* max-height: 110px; */
  /* height: 160px; */
  /* overflow: hidden; */
}

.loginMethodBox .loginMethodItem {
  margin: 0 auto;
  max-width: 100%;
  min-height: 42px;
  overflow: hidden;
}

/*  */
.loginMethodItem .van-button--info {
  background-color: #c520d9;
  border: 1px solid #c520d9;
}

.loginMethodBox .van-divider {
  margin-bottom: 0;
}

.loginTipPisition {
  position: fixed;
  bottom: 82px;
  width: 100%;
  height: 35px;
}

.nickName {
  margin: 0 auto;
  width: 100%;
  min-height: 37.3px;
}

.nickNameInp {
  margin: 0 auto;
  margin-top: 25px;
  width: 100%;
  height: 35px;
}

.loginMethodBox .van-cell::after {
  border-bottom: none;
}

.van-cell .van-cell__title span {
  color: white;
}

.van-cell__value .van-field__control {
  color: white;
}

.van-cell .van-count-down {
  color: white;
}
</style>
