import { render, staticRenderFns } from "./UserAction.vue?vue&type=template&id=50a93f04&scoped=true"
import script from "./UserAction.vue?vue&type=script&lang=js"
export * from "./UserAction.vue?vue&type=script&lang=js"
import style0 from "./UserAction.vue?vue&type=style&index=0&id=50a93f04&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50a93f04",
  null
  
)

export default component.exports