var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.$store.state.isLogin)?_c('div',{staticClass:"loginTipBox"},[_vm._m(0),_vm._v(" "+_vm._s(_vm.$t("common.loginTip.signInToPlayAllGames"))+" "),_c('van-button',{staticStyle:{"float":"right","margin-top":"7px","margin-right":"7px","border-radius":"15px","min-width":"75px"},attrs:{"color":"#52e400","size":"mini"},on:{"click":function($event){return _vm.showLoginOptionActionSheet(true)}}},[_vm._v(" "+_vm._s(_vm.$t("common.loginTip.signInNow")))])],1):_vm._e(),_c('van-action-sheet',{staticStyle:{"background-color":"#232631","color":"white"},attrs:{"title":""},on:{"closed":_vm.closeLoginActionSheet},model:{value:(_vm.$store.state.isLoginActionSheet),callback:function ($$v) {_vm.$set(_vm.$store.state, "isLoginActionSheet", $$v)},expression:"$store.state.isLoginActionSheet"}},[_c('div',{staticClass:"loginPageImg"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("@/assets/loginImg.png"),"alt":""}})]),_c('div',{staticClass:"loginMethodBox"},[_c('van-divider',{style:({
        color: 'white',
        borderColor: 'gray',
        fontSize: '9px',
        padding: '0 10px',
        margin: '7px 0',
        marginTop: '18px',
      })},[_vm._v(" "+_vm._s(_vm.$t("common.loginTip.selectAcount"))+" ")]),_c('div',{staticClass:"loginMethodItem",on:{"click":function($event){return _vm.loginMethod('google')}}},[_c('van-button',{staticStyle:{"font-size":"12px","border-radius":"10px","height":"37px","width":"100%","overflow":"hidden","background-image":"linear-gradient(to right, #fe46ee, #c000f4)","outline":"none"},attrs:{"size":"small","type":"info","block":""}},[_vm._v(" "+_vm._s(_vm.$t("common.loginTip.signInWithGoogle"))+" ")])],1),_c('div',{staticClass:"loginMethodItem",on:{"click":function($event){return _vm.loginMethod('email')}}},[_c('van-button',{staticStyle:{"font-size":"12px","border-radius":"10px","height":"37px","width":"100%","background-image":"linear-gradient(to right, #fe46ee, #c000f4)"},attrs:{"size":"small","type":"info","block":""}},[_vm._v(" "+_vm._s(_vm.$t("common.loginTip.signInWithEmail"))+" ")])],1)],1)]),_c('van-action-sheet',{staticStyle:{"background-color":"#232631","color":"white"},attrs:{"title":""},model:{value:(_vm.loginByEmailCheckCode),callback:function ($$v) {_vm.loginByEmailCheckCode=$$v},expression:"loginByEmailCheckCode"}},[_c('div',{staticClass:"loginPageImg"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("@/assets/loginImg.png"),"alt":""}})]),_c('div',{staticClass:"loginMethodBox"},[_c('van-divider',{style:({
        color: 'white',
        borderColor: 'gray',
        fontSize: '9px',
        padding: '0 10px',
        margin: '5px 0',
        marginTop: '18px',
      })},[_vm._v(" "+_vm._s(_vm.$t("common.loginTip.emailAccountLogin"))+" ")]),_c('van-field',{staticStyle:{"max-height":"39px","background-color":"#45474d","line-height":"18px","border-radius":"10px","font-size":"12px","border":"1px solid #c708f4"},attrs:{"label":_vm.$t('common.login.account'),"placeholder":_vm.$t('common.loginTip.enterEmail')},model:{value:(_vm.loginInfo.email),callback:function ($$v) {_vm.$set(_vm.loginInfo, "email", $$v)},expression:"loginInfo.email"}}),_c('van-field',{staticStyle:{"max-height":"40px","background-color":"#45474d","line-height":"18px","border-radius":"10px","font-size":"12px","margin":"10px 0","border":"1px solid #c708f4"},attrs:{"center":"","clearable":"","label":_vm.$t('common.login.verifyCode'),"placeholder":_vm.$t('common.loginTip.enterCheckCode')},scopedSlots:_vm._u([{key:"button",fn:function(){return [(!_vm.sent)?_c('van-button',{staticStyle:{"border-radius":"6px"},attrs:{"size":"mini","color":"#c708f2","type":"primary"},on:{"click":_vm.sendCheckCode}},[_vm._v(" "+_vm._s(_vm.$t("common.loginTip.sendCheckCode"))+" ")]):(_vm.sent)?_c('van-count-down',{attrs:{"time":_vm.limitTime,"format":"HH:mm:ss"}}):_vm._e()]},proxy:true}]),model:{value:(_vm.loginInfo.checkCode),callback:function ($$v) {_vm.$set(_vm.loginInfo, "checkCode", $$v)},expression:"loginInfo.checkCode"}}),_c('van-button',{staticStyle:{"background-image":"linear-gradient(to right, #ff48ef, #c000f4)","border":"none","max-height":"40px","border-radius":"10px"},attrs:{"type":"primary","block":""},on:{"click":_vm.signInByEmail}},[_vm._v(_vm._s(_vm.$t("common.login.signIn")))])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loginTipIcon"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/loginTipImg.png"),"alt":""}})])
}]

export { render, staticRenderFns }