<template>
  <div
    :style="{
      // paddingBotom:'120px',
      width: pageWidth,
      minHeight: '800px',
      backgroundColor: backColor,
    }"
  >
    <Navi></Navi>

    <div class="topOperateNavBox">
      <div class="favorite" @click="userActionchange('favorite')">
        <img
          style="width: 28px; vertical-align: middle"
          :src="$Host + '/images/like.png'"
          alt=""
        />
        {{ $t("common.userAction.favorite") }}
      </div>
      <div class="history" @click="userActionchange('history')">
        <img
          style="width: 28px; vertical-align: middle"
          :src="$Host + '/images/visit.png'"
          alt=""
        />
        {{ $t("common.userAction.history") }}
      </div>
    </div>

    <div style="background-color: #15161c; padding-bottom: 1px">
      <div class="none" v-if="isShowNullTip">
        <img src="@/assets/historynull.png" alt="" />
      </div>
      <div class="favoriteBox" v-if="isChooseFavorite">
        <!--eslint-disable-next-line-->
        <div class="userFavotiteGameItem" v-for="item in favoriteGameData">
          <Game :game="item"></Game>
        </div>
        <div class="gameItemPlaceOrder"></div>
        <div class="gameItemPlaceOrder"></div>
        <div class="gameItemPlaceOrder"></div>
      </div>
      <!--eslint-disable-next-line-->
      <div
        class="historyBox"
        v-else
        v-for="item in historyGameData"
        @click="toGames(item)"
      >
        <div class="historyGameImg">
          <div class="historyGameItemName" style="color: white">
            {{ item.gameName }}
          </div>
          <div class="gameNameShadow">
            <img
              src="@/assets/gameItemBack.png"
              style="width: 100%; height: 100%"
              alt=""
            />
          </div>
          <img class="historyBackImg" :src="item.image" alt="" />
        </div>
        <div class="historyGameInfo">
          <div class="historyGameName">
            {{ item.gameName }}
          </div>
          <div class="description">
            {{ $t("common.userAction.start") }}
          </div>
          <div class="openNums">
            {{ item.openNum }} {{ $t("common.userAction.played") }}
          </div>
        </div>
      </div>
    </div>

    <div class="placeorder"></div>

    <footer class="bottom">
      <Bottom></Bottom>
    </footer>
  </div>
</template>

<script>
import Bottom from "@/components/bottom/Bottom.vue";
import Navi from "./Navi.vue";
import Game from "@/components/game/Game.vue";
import { Toast } from "vant";
export default {
  data() {
    return {
      userInfo: {},
      pageHeight: "",
      pageWidth: "",
      backColor: "#15161c",
      isChooseFavorite: false,
      favoriteGameData: [],
      historyGameData: [],
      userCollectData: [],
      isShowNullTip: false,
    };
  },
  methods: {
    isShowNullTipControll() {
      if (!this.isChooseFavorite) {
        if (this.historyGameData == null || this.historyGameData.length <= 0) {
          this.isShowNullTip = true;
        } else {
          this.isShowNullTip = false;
        }
      } else {
        if (
          this.favoriteGameData == null ||
          this.favoriteGameData.length <= 0
        ) {
          this.isShowNullTip = true;
        } else {
          this.isShowNullTip = false;
        }
      }
    },

    toGames(item) {
      this.$axios
        .get(this.$Host + "/client/game/openGame?id=" + item.id)
        .then((res) => {
          window.open(item.gameUrl, "_blank");
        })
        .catch((err) => {});
    },

    userActionchange(val) {
      this.$store.commit("userActionchange", val);
      let his = document.querySelector(".history");
      let fav = document.querySelector(".favorite");

      if (this.$store.state.userAction == "history") {
        his.className = "history selected";
        fav.className = "favorite";
        this.isChooseFavorite = false;
        this.isShowNullTipControll();
      } else {
        fav.className = "favorite selected";
        his.className = "history";
        this.isChooseFavorite = true;
        this.filterFavoriteGame();
        this.isShowNullTipControll();
      }
    },

    filterFavoriteGame() {
      if (JSON.stringify(this.userInfo) == "{}") {
        Toast(this.$t("common.axios.noLogin"));
        setTimeout(() => {
          this.$router.push("/login");
        }, 1500);
        return;
      }
      let gameData = JSON.parse(
        sessionStorage.getItem("7topgame.com-game-list")
      );

      if (gameData == null) {
        setTimeout(() => {
          this.$router.push("/");
        }, 1500);
      }

      let userCollectRequestResult = this.getUserFavorite();
      let userCollect = userCollectRequestResult
        .then((res) => {
          if (res.data.code == 10041) {
            if (res.data.data.length <= 0) {
              return;
            }
            let favoriteGames = [];
            userCollect = res.data.data;
            for (let i = 0; i < gameData.length; i++) {
              const game = gameData[i];
              for (let j = 0; j < userCollect.length; j++) {
                const collect = userCollect[j];
                if (game.id == collect.gameId) {
                  favoriteGames.push(game);
                }
              }
            }
            this.favoriteGameData = favoriteGames;
            this.isShowNullTipControll();
            localStorage.setItem(
              "7topgame.com-user-collect",
              JSON.stringify(userCollect)
            );
          }
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"));
        });
    },

    async getUserFavorite() {
      let result = await this.$axios.get(
        this.$Host + "/client/collect/findByTid?tid=" + this.userInfo.id
      );
      return result;
    },
  },
  components: {
    Bottom,
    Navi,
    Game,
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("7topgame.com-user-info"));

    // this.findGameByPage()
    let userAction = this.$store.state.userAction;

    this.userActionchange(userAction);

    this.historyGameData = JSON.parse(
      localStorage.getItem("7topgame.com-user-history")
    );

    this.isShowNullTipControll();

    let height = window.innerHeight;
    let width = window.innerWidth;
    this.pageHeight = height + "px";
    this.pageWidth = width + "px";
  },
};
</script>

<style scoped>
/* .bottom {
    position: fixed;
    bottom: 0;
    width: 99.5%;
    height: 40px;
    background-color: #1b2746;
} */

.topOperateNavBox {
  display: flex;
  margin: 10px auto;
  width: 91%;
  height: 40px;
  border-radius: 10px;
  background-color: #1c2028;
}

.topOperateNavBox div {
  flex: 1;
  text-align: center;
  line-height: 40px;
  color: #555;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
}

.topOperateNavBox .selected {
  background-color: #3d1e6c;
  color: white;
  border-bottom: 2px solid #6313db;
}

.favoriteBox {
  display: flex;
  flex-wrap: wrap;
  /* 允许换行 */
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 91%;
}

.userFavotiteGameItem {
  flex-basis: calc(100% / 3.3);
  margin: 5px auto;
  border-radius: 10px;
  /* overflow: hidden; */
}

.favoriteBox .gameItemPlaceOrder {
  flex-basis: calc(100% / 3.3);
  margin: 5px auto;
}

.historyBox {
  margin: 10px auto;
  display: flex;
  width: 91%;
  height: 20%;
  max-height: 130px;
  overflow: hidden;
  border-radius: 8px;
  background-color: rgb(44, 41, 41);
}

/* .historyBox {
    flex: 1;
    width: 30%;
    height: 100%;
    margin: 0 auto;
    margin-top: 12px;
} */

.historyBox .historyGameImg {
  flex: 0.6;
  position: relative;
  margin: 0 auto;
  max-width: 107px;
  max-height: 130px;
  border-radius: 10px;
  overflow: hidden;
}

.historyBox .historyGameInfo {
  flex: 1.3;
  margin: 0 5px;
  padding-bottom: 10px;
}

.historyGameImg .historyBackImg {
  /* position: relative;
    top: 50%;
    left: 50%; */
  max-width: 107px;
  height: 130px;
  /* transform: translate(-50%, -50%); */
  overflow: hidden;
}

.gameNameShadow {
  position: absolute;
  bottom: -2px;
  left: -2px;
  margin: 0 auto;
  width: 104%;
  height: 40%;
}

.historyGameInfo .historyGameName {
  margin: 10px;
  font-size: 18px;
  text-indent: 1rem;
  font-weight: bold;
  color: #ccc;
}

.historyGameInfo .description {
  margin: 10px;
  font-size: 14px;
  text-indent: 1rem;
  color: white;
}

.historyGameInfo .openNums {
  margin: 10px;
  font-size: 14px;
  text-indent: 1rem;
  color: white;
}

.historyGameItemName {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 23%;
  text-align: center;
  line-height: 32px;
  font-size: 13px;
  z-index: 1;
}

.placeorder {
  width: 100%;
  height: 60px;
  background-color: #15161c;
}

.none {
  margin: 0px auto;
  margin-top: 50px;
  width: 200px;
  height: 200px;
  /* border-radius: 100px; */
  overflow: hidden;
}

.none img {
  width: 100%;
  height: 100%;
}
</style>
