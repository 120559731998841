<template>
    <div>
        <div class="bottomBox">
            <div  class="bottomBoxItem" @click="changePage(0)">
                <div>
                    <img class="mainBottomIcon" src="@/assets/home.png" alt="">
                </div>
                <div class="bottomText"> {{ $t('common.bottom.home') }}</div>
                <img v-if="isChooseHomeMenu && isMainPage" class="bottomMainBackgroundImg"
                    src="@/assets/bottomLight.png" alt="">
            </div>

            <div class="bottomBoxItem" @click="changePage(1)">
                <div>
                    <img class="mainBottomIcon" src="@/assets/mine.png" alt="">
                </div>
                <div class="bottomText"> {{ $t('common.bottom.profile') }}</div>
                <img v-if="!isChooseHomeMenu && isMainPage" class="bottomProfileBackgroundImg"
                    src="@/assets/bottomLight.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isChooseHomeMenu: true,
            isMainPage: true,
        }
    },
    beforeCreate() {

    },
    methods: {

        changeBottomSelected() {
            let icons = document.querySelectorAll('.mainBottomIcon')
            this.isMainPage = true

            if (this.$route.path.includes("/profile")) {
                document.querySelectorAll('.bottomBoxItem')[1].className = 'bottomBoxItem selected'
                document.querySelectorAll('.bottomBoxItem')[0].className = 'bottomBoxItem'
                icons[0].src = require('@/assets/home.png')
                icons[1].src = require('@/assets/mineSelected.png')
                this.isChooseHomeMenu = false
            } else {
                document.querySelectorAll('.bottomBoxItem')[0].className = 'bottomBoxItem selected'
                document.querySelectorAll('.bottomBoxItem')[1].className = 'bottomBoxItem'
                icons[0].src = require('@/assets/homeSelected.png')
                icons[1].src = require('@/assets/mine.png')
                this.isChooseHomeMenu = true
            }

            if (!(this.$route.path.includes("/menu") || this.$route.path.includes("/profile"))) {
                this.isMainPage = false
            }

        },

        changePage(index) {
            let items = document.querySelectorAll('.bottomBoxItem')
            let icons = document.querySelectorAll('.mainBottomIcon')

            for (let i = 0; i < items.length; i++) {
                const element = items[i];
                if (i == index) {
                    element.className = 'bottomBoxItem selected'
                } else {
                    element.className = 'bottomBoxItem'
                    icons[1].src = require('@/assets/mine.png')
                }
            }

            if (index == 0) {
                this.isChooseHomeMenu = true
                icons[0].src = require('@/assets/homeSelected.png')
                icons[1].src = require('@/assets/mine.png')
                let source = JSON.parse(sessionStorage.getItem('7topgame.com-source'))
                this.$router.push(source.routerPath)
            } else {
                icons[0].src = require('@/assets/home.png')
                icons[1].src = require('@/assets/mineSelected.png')
                this.isChooseHomeMenu = false
                this.$router.push('/profile/info')
            }
        },
    },

    watch: {
        $route: function (to, from) {
            this.changeBottomSelected();
        },
    },

    mounted() {
        this.changeBottomSelected()
    }
}
</script>

<style scoped>
.bottomBox {
    position: relative;
    margin: 0 auto;
    display: flex;
    width: 91%;
    height: 55px;
    border-radius: 30px;
    border: 2px solid #9d29f6;
    background-color: #20262d;
    overflow: hidden;
}

.bottomText {
    margin-top: -2px;
    font-size: 10px;
}

.bottomBoxItem {
    position: relative;
    flex: 1;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    color: #929292;
}

.selected {
    color: #ab26ad;
}

.mainBottomIcon {
    width: 26px;
    height: 26px;
}

.bottomMainBackgroundImg {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -50%;
    width: 100%;
}

.bottomProfileBackgroundImg {
    position: absolute;
    bottom: 0px;
    right: 50%;
    margin-right: -50%;
    width: 100%;
}
</style>
