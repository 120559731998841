import { render, staticRenderFns } from "./Mini80.vue?vue&type=template&id=15bb74dd&scoped=true"
import script from "./Mini80.vue?vue&type=script&lang=js"
export * from "./Mini80.vue?vue&type=script&lang=js"
import style0 from "./Mini80.vue?vue&type=style&index=0&id=15bb74dd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15bb74dd",
  null
  
)

export default component.exports