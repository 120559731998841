<template>
    <div>
        <div class="mainPage" :style="{
            height: pageHeight,
            width: pageWidth,
            backgroundColor: backColor,
        }">
            <div class="websiteWords"> {{ $t('common.register.bannerWord') }}</div>

            <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
                OR
            </van-divider>

            <div class="signInBox">
                <van-field v-model="registerInfo.email" :label="$t('common.register.account')"
                    placeholder="Enter your email" />
                <van-field v-model="registerInfo.checkCode" center clearable :label="$t('common.register.verifyCode')"
                    placeholder="Enter check code">
                    <template #button>
                        <van-button size="small" v-if="!sent" @click="sendCheckCode" type="primary">
                            {{ $t('common.register.sendCode') }}
                        </van-button>
                        <van-count-down v-else :time="limitTime" format="HH:mm:ss" />
                    </template>
                </van-field>

                <van-field v-model="registerInfo.password" :label="$t('common.register.password')"
                    placeholder="Enter your password" />

                <van-field v-model="registerInfo.checkPassword" :label="$t('common.register.password')"
                    placeholder="Enter password again" />

                <van-button type="primary" block @click="register">{{$t('common.register.signUp') }}</van-button>

            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from 'vant';
import request from "@/request/request";
export default {
    data() {
        return {
            registerInfo: {
                email: "",
                password: "",
                checkPassword: "",
                checkCode: '',
                avator: ""
            },
            Host: request.HOST,
            pageHeight: "",
            pageWidth: "",
            backColor: "#15161c",
            sent: false,
            limitTime: 0
        };
    },
    mounted() {
        let height = window.innerHeight;
        let width = window.innerWidth;
        this.pageHeight = height - 2 + "px";
        this.pageWidth = width - 2 + "px";
    },
    methods: {
        register() {
            let checkEmailFormatResult = this.checkEmailFormat(this.registerInfo.email);
            if (checkEmailFormatResult == 0) {
                Toast('The mailbox format is incorrect or not supported');
                return
            }
            if (this.registerInfo.password.length < 6) {
                Toast('Your password length is less than 6');
                return
            }
            if (this.registerInfo.password !== this.registerInfo.checkPassword) {
                Toast('Two passwords do not match');
                return
            }
            this.$axios
                .post(this.$Host + '/client/user/register', this.registerInfo)
                .then(res => {
                    if (res.data.code == 10041) {
                        Toast(res.data.msg)
                        setTimeout(() => {
                            this.$router.push('/login')
                        }, res.data.data + 1000);
                    }
                })
                .catch(err => {
                    Toast(err);
                })
        },
        sendCheckCode() {
            // const { v4: uuidv4 } = require('uuid');
            // const uuid = uuidv4();
            // localStorage.setItem('7topgame.com-register-code-uniqueId', uuid)
            let checkEmailFormatResult = this.checkEmailFormat(this.registerInfo.email);
            if (checkEmailFormatResult == 0) {
                Toast('The mailbox format is incorrect or not supported');
                return
            }
            this.$axios
                .get(this.$Host + '/client/user/sendEmailCheckCode?email=' + this.registerInfo.email)
                .then(res => {
                    if (res.data.code == 20100 || res.data.code == 10011) {
                        this.sent = true
                        this.limitTime = res.data.data + 1000
                        setTimeout(() => {
                            this.sent = false
                        }, res.data.data + 1000);
                        
                    } else {
                        Toast(res.data.msg);
                    }
                })
                .catch(err => {
                    Toast(err);
                })
        },
        checkEmailFormat(email) {
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            if (emailPattern.test(email)) {
                return 1
            } else {
                return 0
            }
        },
    },
};
</script>

<style scoped>
.mainPage {
    padding: 1px;
}

.websiteWords {
    margin: 10px auto;
    margin-top: 30px;
    width: 95%;
    height: 80px;
    line-height: 80px;
    border-radius: 8px;
    font-size: 24px;
    color: white;
    text-align: center;
}

.signInBox {
    margin: 0 auto;
    width: 82%;
    height: 55%;
    overflow: hidden;
    border-radius: 10px;
    /* border: 1px solid #ccc; */
}

.signInBox .van-field {
    margin: 20px auto;
}
</style>
