<template>
  <div
    class="allGameByClassification"
    :style="{
      width: pageWidth,
      minHeight: '900px',
      // height: pageHeight,
      backgroundColor: backColor,
    }"
  >
    <van-sticky>
      <Navi></Navi>
    </van-sticky>

    <!-- <div class="allClassifyGamesBox">
      <img style="height: 25px; float: left;margin-right: 10px;" :src="icon" alt="" />
      <div style="line-height: 25px;"> {{ classification }}</div>
    </div> -->

    <div class="gameClassificationTopBackImg">
      <img style="width: 100%; min-height: 157px" :src="backImg" alt="" />
    </div>

    <div class="gamesBox">
      <!--eslint-disable-next-line-->
      <div class="gamesItem" v-for="item in gameData">
        <Game :game="item"> </Game>
      </div>

      <div class="gameItemPlaceOrder"></div>
      <div class="gameItemPlaceOrder"></div>
    </div>

    <div class="noMoreBox">{{ $t("common.bottom.noMore") }}</div>

    <div style="width: 100%; height: 100px"></div>

    <footer class="bottom">
      <LoginTip></LoginTip>
    </footer>
  </div>
</template>

<script>
import Bottom from "@/components/bottom/Bottom.vue";
import Navi from "./Navi.vue";
import Game from "@/components/game/Game.vue";
import LoginTip from "@/components/logintip/LoginTip.vue";
import { mapState } from "vuex";
import { Toast } from "vant";
import api from "@/request/api";
export default {
  data() {
    return {
      classification: {},
      icon: "",
      gameData: [],
      pageHeight: "",
      pageWidth: "",
      backColor: "#15161c",
      backImg: "",
    };
  },
  methods: {
    filterFavoriteGame() {
      if (JSON.stringify(this.userInfo) == "{}") {
        Toast(this.$t("common.axios.noLogin"));
        setTimeout(() => {
          this.changeLoginStatus(true);
        }, 1500);
        // return;
      }

      let gameData = JSON.parse(
        sessionStorage.getItem("7topgame.com-game-list")
      );

      if (gameData == null) {
        setTimeout(() => {
          this.$router.push("/");
        }, 1500);
      }

      let userCollectRequestResult = this.getUserFavorite();
      let userCollect = userCollectRequestResult
        .then((res) => {
          if (res.data.code == 10041) {
            if (res.data.data.length <= 0) {
              return;
            }
            let favoriteGames = [];
            userCollect = res.data.data;
            for (let i = 0; i < gameData.length; i++) {
              const game = gameData[i];
              for (let j = 0; j < userCollect.length; j++) {
                const collect = userCollect[j];
                if (game.id == collect.gameId) {
                  favoriteGames.push(game);
                }
              }
            }
            this.gameData = favoriteGames;
            localStorage.setItem(
              "7topgame.com-user-collect",
              JSON.stringify(userCollect)
            );
          }
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"));
        });
    },

    async getUserFavorite() {
      let userInfo = JSON.parse(localStorage.getItem("7topgame.com-user-info"));
      let result = await api.getUserFavorite(userInfo.id);
      return result;
    },

    changeLoginStatus(e) {
      this.$store.commit("changeLoginStatus", e);
    },

    async findGameByPageAndClassify() {
      let classification = this.$route.query.classification;

      if (
        classification == this.$t("common.profile.profileClassificationHistory")
      ) {
        let historyData = localStorage.getItem("7topgame.com-user-history");
        this.gameData = JSON.parse(historyData);
        return;
      }

      if (
        classification == this.$t("common.profile.profileClassificationLike")
      ) {
        this.filterFavoriteGame();
        return;
      }

      if (
        classification == this.$t("common.profile.profileClassificationGuess")
      ) {
        let randomRecommandGames = JSON.parse(
          localStorage.getItem("7topgame.com-random-recommand-games")
        );
        this.gameData = randomRecommandGames;
        return;
      }

      let source = JSON.parse(sessionStorage.getItem("7topgame.com-source"));
      let cond = {
        begin: 1,
        size: 50,
        lang: localStorage.getItem("7topgame.com-user-lang"),
        classifyName: classification,
        sourceId: source.id,
      };

      try {
        let axiosResult = await api.findGameByPage(cond);
        if (axiosResult.code == 10041) {
          this.gameData = this.filterCollectGame(axiosResult.data.data);
          return;
        }
        Toast(this.$t("common.axios.failed"));
      } catch (error) {
        Toast(this.$t("common.axios.failed"));
      }
    },

    filterCollectGame(data) {
      // if (!this.$store.state.isLogin) {
      //   Toast(this.$t("common.axios.noLogin"));
      //   return;
      // }

      if (data == undefined) {
        return;
      }

      let userCollect = JSON.parse(
        localStorage.getItem("7topgame.com-user-collect")
      );

      if (JSON.stringify(data) == "[]") {
        return [];
      }

      if (JSON.stringify(userCollect) == "[]" || userCollect == null) {
        for (let i = 0; i < data.length; i++) {
          data[i].isCollect = false;
        }
        return data;
      }

      for (let j = 0; j < userCollect.length; j++) {
        for (let i = 0; i < data.length; i++) {
          const collect = userCollect[j];
          if (data[i].id == collect.gameId) {
            data[i].isCollect = true;
          }
        }
      }

      return data;
    },

    getIcon(e) {
      let classes = JSON.parse(sessionStorage.getItem("7topgame.com-classify"));
      for (let i = 0; i < classes.length; i++) {
        const element = classes[i];
        if (e == element.name) {
          let index = element.icon.lastIndexOf(".");
          let filename = element.icon.substring(0, index);
          let filetype = element.icon.substring(index, element.icon.length);
          this.icon = this.$Host + filename + "2" + filetype;
          break;
        }
      }
    },

    initTopClassificationImg() {
      let classification = this.$route.query.classification;
      let classificationList = JSON.parse(
        sessionStorage.getItem("7topgame.com-classify")
      );

      for (let i = 0; i < classificationList.length; i++) {
        if (classification == classificationList[i].name) {
          this.classification = classificationList[i];
          break;
        }
      }

      if (
        classification == this.$t("common.profile.profileClassificationHistory")
      ) {
        this.backImg = require("@/assets/historyBackImg.png");
        return;
      }

      if (
        classification == this.$t("common.profile.profileClassificationLike")
      ) {
        this.backImg = require("@/assets/likeBackImg.png");
        return;
      }

      if (
        classification == this.$t("common.profile.profileClassificationGuess")
      ) {
        this.backImg = require("@/assets/GuessYouLike.png");
        return;
      }

      switch (this.classification.parentId) {
        case 4:
          this.backImg = require("@/assets/goldBackImg.png");
          break;
        case 5:
          this.backImg = require("@/assets/AdventureBackImg.png");
          break;
        case 6:
          this.backImg = require("@/assets/gameClassificationTopBackImg.png");
          break;
        case 7:
          this.backImg = require("@/assets/coinBackImg.png");
          break;
        case 8:
          this.backImg = require("@/assets/gameClassificationTopBackImg.png");
          break;
        case 9:
          this.backImg = require("@/assets/diamondsBackImg.png");
          break;
        default:
          this.backImg = require("@/assets/gameClassificationTopBackImg.png");
          break;
      }
    },

    handleChangeGameDataByDetail(data) {
      this.$EventBus.$on("handleChangeGameDataByDetail", (data) => {
        for (let i = 0; i < this.gameData.length; i++) {
          this.$set(this.gameData[i], this.gameData[i].isCollect, data.collect);
        }

        let gameList = JSON.parse(
          sessionStorage.getItem("7topgame.com-game-list")
        );
        for (let i = 0; i < gameList.length; i++) {
          if (data.id == gameList[i].id) {
            gameList[i].isCollect = data.collect;
            break;
          }
        }
        sessionStorage.setItem(
          "7topgame.com-game-list",
          JSON.stringify(gameList)
        );
      });
    },
  },

  beforeDestroy() {
    this.$EventBus.$off("handleChangeGameDataByDetail");
  },

  components: {
    // Bottom,
    Navi,
    Game,
    LoginTip,
    // Classify
  },
  watch: {
    $route: function (to, from) {
      this.classification.name = to.query.classification;
      this.gameData = [];
      setTimeout(() => {
        this.findGameByPageAndClassify();
      }, 500);
    },
  },

  mounted() {
    let source = JSON.parse(sessionStorage.getItem("7topgame.com-source"));
    if (source == null) {
      this.$router.go(-1);
      return;
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let userInfo = JSON.parse(localStorage.getItem("7topgame.com-user-info"));

    if (userInfo.isTourist) {
      this.changeLoginStatus(false);
    } else {
      this.changeLoginStatus(true);
    }

    this.initTopClassificationImg();

    this.getIcon(this.classification.name);
    this.findGameByPageAndClassify();
    this.handleChangeGameDataByDetail();

    // let height = window.innerHeight;
    // let width = window.innerWidth;
    // this.pageHeight = height + "px";
    // this.pageWidth = width + "px";
  },
};
</script>

<style scoped>
.allClassifyGamesBox {
  margin: 0 auto;
  margin-top: 10px;
  width: 89%;
  line-height: 25px;
}

.allGameByClassification {
  background-image: url("@/assets/mainBackimg2.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
}

.allClassifyGamesBox div {
  margin-bottom: 10px;
  line-height: 20px;
  color: #b7cbec;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.gamesBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  width: 93%;
}

.gamesBox .gamesItem {
  /* flex-basis: calc(100% / 3.3); */
  flex-basis: calc(100% / 2.1);
  margin: 5px auto;
  max-width: 166px;
  max-height: 117px;
  overflow: hidden;
}

.gamesBox .gameImg {
  position: absolute;
  left: 0;
  object-fit: cover;
  width: 100%;
  max-height: 117px;
  border-radius: 15px;
  overflow: hidden;
}

.gameItemPlaceOrder {
  flex-basis: calc(100% / 2.1);
  margin: 5px auto;
  border-radius: 15px;
  max-width: 166px;
  max-height: 117px;
  overflow: hidden;
}

.gameClassificationTopBackImg {
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 7px;
  width: 91%;
  min-height: 147px;
  overflow: hidden;
  border-radius: 15px;
}

.noMoreBox {
  margin: 0 auto;
  width: 91%;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  color: #a1a5a8;
  border-radius: 15px;
}
</style>
