<template>
  <div>
    <div class="mainPage" :style="{
      height: pageHeight,
      width: pageWidth,
    }">
      <div id="websiteWords">{{ $t("common.login.bannerWord") }}</div>

      <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
        OR
      </van-divider>

      <div class="signInBox">
        <van-field v-model="loginInfo.email" :label="$t('common.login.account')" placeholder="Enter your email" />

        <van-field v-model="loginInfo.password" v-if="!signInByEmail" :label="$t('common.login.password')"
          placeholder="Enter your password" />

        <van-field v-model="loginInfo.checkCode" v-else center clearable :label="$t('common.login.verifyCode')"
          placeholder="Enter check code">
          <template #button>
            <van-button size="small" v-if="!sent" @click="sendCheckCode" type="primary">{{ $t('common.loginTip.sendCheckCode')}}
            </van-button>
            <van-count-down v-else-if="sent && signInByEmail" :time="limitTime" format="HH:mm:ss" />
          </template>
        </van-field>

        <div class="loginTypeBox" v-if="!signInByEmail" @click="signInByEmail = true, sent = false">{{
          $t('common.login.signInWithEmail') }}
        </div>
        <div class="loginTypeBox" v-else @click="signInByEmail = false, sent = true">{{
          $t('common.login.signInWithPassword') }}
        </div>

        <van-button type="primary" block @click="login">{{ $t('common.login.signIn') }}</van-button>
      </div>

      <!-- <div class="loginBox">
        <van-field v-model="userId" type="digit" maxlength="20" input-align="center" class="loginIpu"
          :placeholder="$t('common.login.inputTip')" />
        <van-button type="info" style="border-radius: 20px" color="#8238fa" block @click="signInByTourist">{{
          $t("common.login.signInWithTourist") }}
        </van-button>
        <van-divider :style="{
          color: '#9834e9',
          borderColor: '#9834e9',
          padding: '0 16px',
          fontSize: '12px',
        }">
          OR
        </van-divider>
        <van-button type="info" style="margin-top: 10px;border-radius: 20px"
          color="linear-gradient(to bottom, #9535f2, #b22194)" block @click="signInByGoogle">{{
            $t("common.login.signInWithGoogle") }}
        </van-button>
      </div> -->


    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import request from "@/request/request";
export default {
  data() {
    return {
      loginInfo: {
        email: "",
        password: "",
        checkCode: "",
      },
      userId: "",
      Host: request.HOST,
      pageHeight: "",
      pageWidth: "",
      backColor: "#15161c",
      signInByEmail: true,
      sent: false,
      limitTime: 0,
    };
  },
  mounted() {

    this.checkGoogleIsLogin()


    // const authParams = new URLSearchParams({
    //   response_type: 'code', //固定
    //   client_id: '152858144142-ib4465gkcmbb6lghmo2i74jmmj3rkle4.apps.googleusercontent.com', // 请将 YOUR_CLIENT_ID 替换为实际的客户端 ID
    //   scope: 'openid email profile',  //固定
    //   redirect_uri: 'https://www.7topgame.com', // 在Google配置的回调url
    // });

    // const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?${authParams}`;
    // location.href = authUrl



    let height = window.innerHeight;
    let width = window.innerWidth;
    this.pageHeight = height + "px";
    this.pageWidth = width + "px";
  },
  methods: {

    checkGoogleIsLogin() {
      let fullPath = this.$route.fullPath
      let beginIndex = fullPath.lastIndexOf('?')
      let endIndex = fullPath.lastIndexOf('=')

      if (beginIndex == -1 || endIndex == -1) {
        return
      }
      let isSuccess = fullPath.slice(beginIndex + 1, endIndex)


      if (isSuccess == 'error') {
        return
      }

      let userid = fullPath.slice(endIndex + 1)
      // let email = string.replace(/%40/g, '@');

        .get(this.$Host + "/client/user/signInById?id=" + userid)
        .then((res) => {
          Toast(this.$t("common.axios.signInSuccessful"))
          localStorage.setItem(
            "7topgame.com-user-info",
            JSON.stringify(res.data.data)
          );
          setTimeout(() => {
            this.$router.push("/mini80/menu");
          }, 1000);
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"))
        });

    },

    signInByGoogle() {
      this.$axios
        .get(this.$Host + "/client/user/signInWithGoogle")
        .then((res) => {
          if (res.data.code == 10041) {
            location.href = res.data.data
            return;
          }
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"))
        });
    },

    signInByTourist() {
      if (this.userId != "" && this.userId.length > 6) {
        this.$axios
          .get(this.$Host + "/client/tourist/signInById?id=" + this.userId)
          .then((res) => {
            if (res.data.code == 10041) {
              Toast(this.$t("common.axios.signInSuccessful"))
              localStorage.setItem(
                "7topgame.com-user-info",
                JSON.stringify(res.data.data)
              );
              setTimeout(() => {
                this.$router.push("/mini80/menu");
              }, 1000);
              return;
            }
            Toast(this.$t("common.axios.failed"))
          })
          .catch((err) => {
            Toast(this.$t("common.axios.failed"))
          });
      } else {
        let tourist = {
          id: null,
          area: navigator.language || navigator.userLanguage,
          gender: "",
          name: "",
          avatar: "",
        };
        this.$axios
          .post(this.$Host + "/client/tourist/signInWithTourist", tourist)
          .then((res) => {
            if (res.data.code == 10011) {
              Toast(this.$t("common.axios.signInSuccessful"))
              localStorage.setItem(
                "7topgame.com-user-info",
                JSON.stringify(res.data.data)
              );
              setTimeout(() => {
                this.$router.push("/mini80/menu");
              }, 1000);
              return;
            }
            Toast(this.$t("common.axios.failed"))
          })
          .catch((err) => {
            Toast(this.$t("common.axios.failed"))
          });
      }
    },

    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    login() {
      let checkEmailFormatResult = this.checkEmailFormat(this.loginInfo.email);
      if (checkEmailFormatResult == 0) {
        Toast("The mailbox format is incorrect or not supported");
        return;
      }

      let logiUrl = "";
      if (this.signInByEmail) {
        if (this.loginInfo.checkCode.length != 4) {
          Toast("Check code length less than 4");
          return;
        }
        logiUrl = "/client/user/loginByEmailCheckCode";
      } else {
        logiUrl = "/client/user/loginByPassword";
      }

      this.loginInfo.area = navigator.language || navigator.userLanguage;

      this.$axios
        .post(this.$Host + logiUrl, this.loginInfo)
        .then((res) => {
          if (res.data.code == 10041) {
            localStorage.setItem(
              "7topgame.com-user-info",
              JSON.stringify(res.data.data)
            );
            localStorage.setItem("7topgame.com-user-lang", res.data.data.area);
            setTimeout(() => {
              this.$router.push("/mini80/menu");
            }, 500);
          }
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"))
        });
    },

    sendCheckCode() {
      // const { v4: uuidv4 } = require('uuid');
      // const uuid = uuidv4();
      // localStorage.setItem('7topgame.com-register-code-uniqueId', uuid)

      let checkEmailFormatResult = this.checkEmailFormat(this.loginInfo.email);
      if (checkEmailFormatResult == 0) {
        Toast("The mailbox format is incorrect or not supported");
        return;
      }

      this.$axios
        .get(
          this.$Host +
          "/client/user/sendEmailCheckCode?email=" +
          this.loginInfo.email
        )
        .then((res) => {
          if (res.data.code == 20100 || res.data.code == 10011) {
            this.sent = true;
            this.limitTime = res.data.data + 1000;
            setTimeout(() => {
              this.sent = false;
            }, res.data.data + 1000);
          } else {
            Toast(res.data.msg);
          }
        })
        .catch((err) => {
          Toast(this.$t("common.axios.failed"))
        });
    },

    checkEmailFormat(email) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (emailPattern.test(email)) {
        return 1;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style scoped>
.mainPage {
  padding: 1px;
  background-image: url("@/assets/background2.png");
  min-height: 700px;
  max-height: 900px;
  background-size: cover;
  background-position: center;
}

.mainPage #websiteWords {
  margin: 10px auto;
  margin-top: 50px;
  width: 60%;
  border-radius: 8px;
  font-size: 40px;
  color: rgb(236, 228, 228);
  text-align: center;
  text-shadow: 3px 3px 3px #760d6d;
  font-weight: 600;
}

.signInBox {
  margin: 0 auto;
  width: 92%;
  height: 45%;
  overflow: hidden;
  border-radius: 10px;
  /* border: 1px solid #ccc; */
}

.signInBox .van-field {
  margin: 20px auto;
}

.loginTypeBox {
  margin-bottom: 20px;
  width: 50%;
  height: 20px;
  font-size: 12px;
  color: white;
}

.loginBox {
  margin: 0 auto;
  margin-top: 70px;
  width: 82%;
  overflow: hidden;
}

.loginBox .loginIpu {
  margin-bottom: 30px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  color: white;
}

.loginIpu .van-field__control {
  color: white;
}

.van-field__control--center {
  color: white;
}

.loginBox .van-cell::after {
  border: none;
}
</style>
