<template>
    <div :style="{
        height: pageHeight,
        width: pageWidth,
        // minHeight: '900px',
        margin: '0 auto',
        // backgroundColor: '#472546',
        background: 'radial-gradient(circle, #a21b80,#080d1b)'
    }">

        <div class="gameIframe">
            <iframe class="gameIframe" :src="startGameInfo.gameUrl" :width="pageWidth" :height="pageHeight"
                frameborder="0px" scrolling="auto"></iframe>
        </div>

        <!-- <div class="closeWindow" @click="closeWindows">
            <van-icon size="25" name="clear" color="#8c8d8f" />
        </div> -->
    </div>
</template>

<script>
import api from '@/request/api';
import http from '@/request/http';
export default {
    props: {
    },
    data() {
        return {
            pageHeight: '',
            pageWidth: '',
            startTime: null,
            endTime: null,
            playGameItemRecord: {},
            startGameInfo: {}
        }
    },
    methods: {

        closeWindows() {
            let gameInfo = null
            this.$store.commit("changeIframeData", gameInfo);
            this.$router.go(-1)
        },

        playGameTotalTime() {
            let data = {
                uid: this.playGameItemRecord.uid,
                gid: this.playGameItemRecord.gid,
                playTime: this.endTime - this.startTime,
            }
            api.recordUserPlayGameTime(data)
        },

        handleCurrentPageChange() {
            let height = window.innerHeight;
            let width = window.innerWidth;
            this.pageHeight = height + "px";
            this.pageWidth = width + "px";
        },

    },

    async mounted() {

        if (this.$store.state.gameIframeGameInfo == null) {
            this.$router.go(-1)
            return
        }

        this.startGameInfo = this.$store.state.gameIframeGameInfo

        this.playGameItemRecord = this.$store.state.gameIframeGameInfo

        this.startTime = new Date().getTime();

        this.handleCurrentPageChange()

        window.addEventListener('resize', () => {
            this.handleCurrentPageChange()
        });

    },

    beforeDestroy() {
        this.endTime = new Date().getTime();
        this.playGameTotalTime()
        // this.$router.go(-1)
    },

}

</script>
<style scoped>
.gameIframe {
    width: 100%;
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.adIframe::-webkit-scrollbar {
    display: none;
}

.closeWindow {
    position: absolute;
    top: 10px;
    right: 15px;
}
</style>